export const MenuItems = [
  {
    title: "Linkedin",
    url: 'https://www.linkedin.com/in/dennis-wang-b2a99370/',
    cName: 'nav-links'
  },
  {
    title: "Medium",
    url: 'https://medium.com/@dwang0816',
    cName: 'nav-links'
  },
  {
    title: "Github",
    url: 'https://github.com/dwang0816',
    cName: 'nav-links'
  }

]